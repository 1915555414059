(function ($) {

    var app = window.app || {};

    app.transition_time = 3 * 1000;
    app.project_list = $('.js-projects-list').find('.project-item');
    app.image_list = $('.js-projects-images').find('img');

    app.max = app.project_list.length;
    app.active_project_index = 0;


    // Activate item of a list. Generic for using in both lists
    app.activate_item = function(list, index){

      list.removeClass('-active');
      $(list[ index ]).addClass('-active');

    }

    // Navigates through itens of lists respecting the max itens
    app.round_navigation = function(project_list, image_list, max){

      if(app.debug) console.log(app.active_project_index);
      app.activate_item(project_list, app.active_project_index);
      app.activate_item(image_list, app.active_project_index);

      app.active_project_index ++;
      app.active_project_index = app.active_project_index % max;

    }

    // Automatic changing of active itens
    app.init_projects_interval = function(){
      app.round_navigation(app.project_list, app.image_list, app.max);
      return setInterval(
            app.round_navigation,
            app.transition_time,
            app.project_list,
            app.image_list, app.max);
    }
    app.project_interval = app.init_projects_interval();

    // Binding transition on click, reseting the automatic interval
    app.project_list.click(function(){
      clearInterval(app.project_interval);

      app.active_project_index = $(this).attr('data-item') ;

      app.activate_item(app.project_list, app.active_project_index);
      app.activate_item(app.image_list, app.active_project_index);

      app.project_interval = app.init_projects_interval();
    })

})(jQuery);