(function ($) {

    var app = window.app || {};

    $(document).ready(function () {

        Parsley.addMessages('pt', {
            defaultMessage: "Este valor parece ser inválido.",
            type: {
              email:        "Este campo deve ser um email válido.",
              url:          "Este campo deve ser um URL válida.",
              number:       "Este campo deve ser um número válido.",
              integer:      "Este campo deve ser um inteiro válido.",
              digits:       "Este campo deve conter apenas dígitos.",
              alphanum:     "Este campo deve ser alfa numérico."
            },
            notblank:       "Este campo não pode ficar vazio.",
            required:       "Este campo é obrigatório.",
            pattern:        "Este campo parece estar inválido.",
            min:            "Este campo deve ser maior ou igual a %s.",
            max:            "Este campo deve ser menor ou igual a %s.",
            range:          "Este campo deve estar entre %s e %s.",
            minlength:      "Este campo é pequeno demais. Ele deveria ter %s caracteres ou mais.",
            maxlength:      "Este campo é grande demais. Ele deveria ter %s caracteres ou menos.",
            length:         "O tamanho deste campo é inválido. Ele deveria ter entre %s e %s caracteres.",
            mincheck:       "Você deve escolher pelo menos %s opções.",
            maxcheck:       "Você deve escolher %s opções ou mais",
            check:          "Você deve escolher entre %s e %s opções.",
            equalto:        "Este valor deveria ser igual."
          });

          Parsley.addMessages('en', {
            defaultMessage: "This value seems to be invalid.",
            type: {
              email:        "This value should be a valid email.",
              url:          "This value should be a valid url.",
              number:       "This value should be a valid number.",
              integer:      "This value should be a valid integer.",
              digits:       "This value should be digits.",
              alphanum:     "This value should be alphanumeric."
            },
            notblank:       "This value should not be blank.",
            required:       "This value is required.",
            pattern:        "This value seems to be invalid.",
            min:            "This value should be greater than or equal to %s.",
            max:            "This value should be lower than or equal to %s.",
            range:          "This value should be between %s and %s.",
            minlength:      "This value is too short. It should have %s characters or more.",
            maxlength:      "This value is too long. It should have %s characters or fewer.",
            length:         "This value length is invalid. It should be between %s and %s characters long.",
            mincheck:       "You must select at least %s choices.",
            maxcheck:       "You must select %s choices or fewer.",
            check:          "You must select between %s and %s choices.",
            equalto:        "This value should be the same."
          });

        var lang = app.extract_current_active_lang();

        if(app.debug) console.log('Locale: ' + lang);

        Parsley.setLocale( lang );

    });


    // Searchs html lang attribute
    app.extract_current_active_lang = function(){

        var lang = $('html').attr('lang');

        if(lang){
            lang = lang.split('-');
            if(lang.length){
                lang = lang[0];
            }
        }

        return lang;

    }


})(jQuery);