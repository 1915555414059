(function($){

    app = window.app || {};

    $(document).ready(function(){

        var form_work = $('form.js-work-form');

        if (form_work.length) {

            if(app.debug) console.log('Initializing job positions');

            // TODO: Transform this ajax call into static template bulding options
            $.ajax({ url: "/api/job_positions-api.json"})
            .done( app.populate_positions );
        }

    });

    app.populate_positions = function(positions){
        if(app.debug) console.log( positions );

        window.positions = positions;

        var form_work = $('form.js-work-form');

        var position_list = form_work.find('.js-position_list');

        var position_tpl = form_work.find('.js-position-item-template');


        positions.forEach(function(position_data){

            var position = position_tpl.clone().html();

            position = app.replace_position_tpl(position, position_data);

            position_list.append( $(position).removeClass('hidden') );

        });

        // on form.js
        app.bind_form_validation($('form.js-work-form'), 4);

        app.populate_more_details(positions);
    }

    app.populate_more_details = function(positions){

        var position_details_list = $('.js-job-details-wrapper');

        var position_details_tpl = position_details_list.find('.js-job-details-template');

        positions.forEach(function(position_data){

            var position_details = position_details_tpl.clone().html();

            position_details = app.replace_position_tpl(position_details, position_data);

            position_details = app.replace_position_repeaters(position_details, position_data);


            position_details = position_details_list.append( $(position_details).removeClass('hidden') );

            if (app.debug) console.log('Binding close btn for ' + position_data.name )

            $(position_details).find('.js-close').click(function(){
                $('.js-job-details').removeClass('open');
            })

        });

        app.bind_more_details();
    }


    app.bind_more_details = function(){

        var available_positions = $('form.js-work-form .js-role-item:not(.hidden)');

        $(available_positions).each(function(){

            var more_btn = $(this).find('.js-more');

            var position_code = $(more_btn).attr('data-position_code');

            if (app.debug) console.log('Binding click for ' + position_code);

            more_btn.click(function(e){
                e.preventDefault();

                app.openDetails(position_code);
            });

        });
    }

    app.openDetails = function(position_code) {
        if (app.debug) console.log('Opening details for ' + position_code);

        $('.js-job-details').removeClass('open');

        $('.js-job-details[data-position_code="'+position_code+'"]').addClass('open');

    }


    app.replace_position_tpl = function(html, data) {

        if (app.debug) console.log('Replacing template fields of ' + data.name )

        var fields = [ 'name', 'code', 'subtitle', 'description' ];
        var filled_html = html;

        fields.forEach(function(field){

            var regex = new RegExp("{{" + field + "}}", "g");

            filled_html = filled_html.replace(regex, data[field] );

        });

        return filled_html;
    }

    app.replace_position_repeaters = function(html, data) {

        if (app.debug) console.log('Replacing repeater fields of ' + data.name )

        var fields = [ 'expected', 'bonus' ];
        var filled_html = $(html);

        fields.forEach(function(field){

            var repeater = $(filled_html).find('[repeater="'+field+'"]');

            data[field].forEach(function( el ){

                repeater.after( '<li>' + el + '</li>' );

            });

        });

        return filled_html;
    }



})(jQuery);