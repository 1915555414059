(function ($) {

  var app = window.app || {};

  // Initial configs
  app.debug = false;

  $(document).ready(function () {

    $('.menu a').click(function () {
      var target = $(this.hash);
      if (target.length == 0) target = $('a[id="' + this.hash.substr(1) + '"]');
      if (target.length == 0) target = $('html');
      $('html, body').animate({
        scrollTop: target.offset().top - 0
      }, 500);
      return false;
    });

    $(document).scroll(function () {
      if ($(this).scrollTop() > 160) {
        $("header").addClass("fixed");
        // $(".hero-simulate-button").addClass("scroll-button");
      } else {
        $("header").removeClass("fixed");
        // $(".hero-simulate-button").removeClass("scroll-button");
      }
    });  
    
    $(document).scroll(function () {
      if ($(this).scrollTop() > 400) {
        $(".line-divider").addClass("hide");
        // $(".hero-simulate-button").addClass("scroll-button");
      } else {
        $(".line-divider").removeClass("hide");
        // $(".hero-simulate-button").removeClass("scroll-button");
      }
    }); 

    $(document).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $(".highlight").addClass("hide");
        // $(".hero-simulate-button").addClass("scroll-button");
      } else {
        $(".highlight").removeClass("hide");
        // $(".hero-simulate-button").removeClass("scroll-button");
      }
    }); 
    
    $(document).scroll(function () {
      if ($(this).scrollTop() > 400) {
        $(".fixed-button").addClass("hide");
        // $(".hero-simulate-button").addClass("scroll-button");
      } else {
        $(".fixed-button").removeClass("hide");
        // $(".hero-simulate-button").removeClass("scroll-button");
      }
    }); 

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      //>=, not <=
      if (scroll >= 100) {
        //clearHeader, not clearheader - caps H
        $(".header-wrapper").addClass("-scrolled");
      } else {
        $(".header-wrapper").removeClass("-scrolled");

      }
    }); //missing );


    $(".transition").waypoint(function (direction) {
      $(this[0, 'element']).toggleClass("-visible");
    }, {
      offset: '90%',
      triggerOnce: true,
      // offset: 'bottom-in-view'
    });


    $(".bio").waypoint(function (direction) {
      $(this[0, 'element']).toggleClass("-visible");
      this.destroy()
    }, {
      offset: '90%',
      triggerOnce: true
      // offset: 'bottom-in-view'
    });


  //   var swiper = new Swiper('.swiper-container', {
  //     spaceBetween: 30,
  //     effect: 'slide',
  //     slidesPerView: 3,
  //       breakpoints: {
  //         // 1200: {
  //         //   slidesPerView: 3,
  //         // },
  //         // 768: {
  //         //   slidesPerView: 3,
  //         // },
  //         560: {
  //           slidesPerView: 2,
  //         }
  //       },
  //     navigation: {
  //         nextEl: '.swiper-button-next',
  //         prevEl: '.swiper-button-prev',
  //     },
  // });



  $(".wrapper-top").mousemove(function(e) {
    // parallaxIt(e, ".highlight", -100);
    parallaxIt(e, ".main-image", -60);
  });
  
  function parallaxIt(e, target, movement) {
    var $this = $(".cover");
    var relX = e.pageX - $this.offset().left;
    var relY = e.pageY - $this.offset().top;
  
    TweenMax.to(target, 1, {
      x: (relX - $this.width() / 3) / $this.width() * movement,
      y: (relY - $this.height() / 3) / $this.height() * movement
    });
  }

  



    // ToggleNav

    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('nav').outerHeight();

    $(window).scroll(function (event) {
      didScroll = true;
    });

    setInterval(function () {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 250);

    function hasScrolled() {
      var st = $(this).scrollTop();

      // Make sure they scroll more than delta
      if (Math.abs(lastScrollTop - st) <= delta)
        return;

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (st > lastScrollTop && st > navbarHeight + 450) {
        // Scroll Down
        $('nav').removeClass('nav-down').addClass('nav-up');
      } else {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
          $('nav').removeClass('nav-up').addClass('nav-down');
        }
      }

      lastScrollTop = st;
    }


    $(function () {
      if ($(".numbers").hasClass('aos-animate')) {
        $('.count').each(function () {
          $(this).prop('Counter', 0).animate({
            Counter: $(this).text()
          }, {
            duration: 4000,
            easing: 'swing',
            step: function (now) {
              $(this).text(Math.ceil(now));
            }
          });
        });
      } else {

      }
    });

    $(document).scroll(function () {
      if ($(this).scrollTop() > 120) {
        $("nav").addClass("-min");
      } else {
        $("nav").removeClass("-min");
      }
    });




  });

})(jQuery);